const TYPHOON_LEVEL_STYLE = {
  N: { name: "", color: "#aaaaaa", size: 4 },
  TD: { name: "热带低压", color: "#ffff00", size: 6 },
  TS: { name: "热带风暴", color: "#f6bf00", size: 8 },
  STS: { name: "强热带风暴", color: "#f08000", size: 10 },
  TY: { name: "台风", color: "#e33b20", size: 12 },
  STY: { name: "强台风", color: "#b60026", size: 14 },
  SuperTY: { name: "超强台风", color: "#860065", size: 16 },
  "Super TY": { name: "超强台风", color: "#860065", size: 16 }
};

export default TYPHOON_LEVEL_STYLE;
