import React, { useMemo, memo, useCallback } from 'react';
import {
  CircleMarker,
  Tooltip,
  Polyline,
  LayerGroup,
  useLeaflet,
} from 'react-leaflet';
import { DomEvent, latLngBounds } from 'leaflet';
import TyphooLevelStyle from './TyphoonLevelStyle';

const TyphoonPath = ({ data: tyData = {}, dispatch, selected }) => {
  const { id6: id, nameZh, nameEn, active, path = [], forecasts = [] } = tyData;
  const { map } = useLeaflet();
  const bbox = useMemo(
    () =>
      path.length < 2
        ? null
        : latLngBounds(path.map(({ lat, lon }) => [lat, lon])).pad(0.25),
    [path]
  );
  const clickHandler = useCallback(
    (e) => {
      if (!selected && bbox) {
        map.fitBounds(bbox);
        DomEvent.stopPropagation(e); // 阻止触发map的click事件
        dispatch({ type: 'select', payload: id });
      }
    },
    [id, bbox, map, selected, dispatch]
  );
  if (!id || path.length < 2) {
    return null;
  }
  const markers = [];
  const lines = [];
  let lastPoint = null;
  for (let i = 0; i < path.length; i++) {
    const { lat, lon, level, idNMC, time, wndSpd, power } = path[i];
    const levelStyle = TyphooLevelStyle[level] || TyphooLevelStyle.N;
    const t = new Date(time * 1000);
    const timestr = `${t.getFullYear()}年${
      t.getMonth() + 1
    }月${t.getDate()}日${t.getHours()}时`;
    const tooltip = (
      <Tooltip>
        <strong>
          {id} {nameZh || nameEn}
        </strong>
        <br />
        {timestr}
        <br />
        {`${wndSpd}m/s`} {power || levelStyle.size}级
      </Tooltip>
    );

    markers.push(
      <CircleMarker
        key={idNMC}
        center={[lat, lon]}
        stroke={false}
        radius={levelStyle.size / 2}
        color={levelStyle.color}
        fillOpacity={1}
        pane={selected ? 'typhoon-marker-selected' : 'typhoon-marker'}
        onClick={clickHandler}
      >
        {tooltip}
      </CircleMarker>
    );
    if (lastPoint) {
      lines.push(
        <Polyline
          key={idNMC}
          positions={[lastPoint, [lat, lon]]}
          weight={2}
          color={levelStyle.color || '#ffff00'}
          pane={selected ? 'typhoon-line-selected' : 'typhoon-line'}
          onClick={clickHandler}
        >
          {tooltip}
        </Polyline>
      );
    }
    lastPoint = [lat, lon];
  }
  if (active && forecasts.length > 0) {
    forecasts[0].path.forEach(({ time, lat, lon, wndSpd, level }) => {
      const levelStyle = TyphooLevelStyle[level] || TyphooLevelStyle.N;
      const t = new Date(time * 1000);
      const timestr = `${t.getFullYear()}年${
        t.getMonth() + 1
      }月${t.getDate()}日${t.getHours()}时`;
      const tooltip = (
        <Tooltip>
          <strong>
            {id} {nameZh || nameEn} 路径预测
          </strong>
          <br />
          {timestr}
          <br />
          {`${wndSpd}m/s`} {levelStyle.size}级
        </Tooltip>
      );
      markers.push(
        <CircleMarker
          key={`${id}-${time}`}
          center={[lat, lon]}
          stroke={false}
          radius={levelStyle.size / 2}
          color={levelStyle.color}
          fillOpacity={0.8}
          pane={selected ? 'typhoon-marker-selected' : 'typhoon-marker'}
          onClick={clickHandler}
        >
          {tooltip}
        </CircleMarker>
      );
      if (lastPoint) {
        lines.push(
          <Polyline
            key={`${id}-${time}`}
            positions={[lastPoint, [lat, lon]]}
            weight={2}
            dashArray="8,8"
            color={'#999999'}
            pane={selected ? 'typhoon-line-selected' : 'typhoon-line'}
            onClick={clickHandler}
          >
            {tooltip}
          </Polyline>
        );
      }
      lastPoint = [lat, lon];
    });
  }
  return (
    <LayerGroup>
      {lines}
      {markers}
    </LayerGroup>
  );
};

export default memo(TyphoonPath);
