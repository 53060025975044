import { useReducer } from 'react';

const reducers = {
  changeStartYear(state, { payload }) {
    const endYear = state.endYear;
    return {
      startYear: payload,
      endYear: endYear < payload ? payload : endYear,
      selected: undefined,
    };
  },
  changeEndYear(state, { payload }) {
    const startYear = state.startYear;
    return {
      startYear: payload < startYear ? payload : startYear,
      endYear: payload,
      selected: undefined,
    };
  },
  changeMinLevel(_state, { payload }) {
    return { minLevel: payload, selected: undefined };
  },
  changeShowMask(_state, { payload }) {
    return { showMask: payload };
  },
  select(_state, { payload }) {
    return { selected: payload };
  },
  unselect(_state) {
    return { selected: undefined };
  },
  toggleShowLine(state) {
    const { showMask } = state;
    const showLine = showMask & 1;
    const showMarker = showMask & 2;
    let mask = (!showLine ? 1 : 0) | (showMarker ? 2 : 0);
    if (!mask) {
      mask = 2;
    }
    return { showMask: mask };
  },
  toggleShowMarker(state) {
    const { showMask } = state;
    const showLine = showMask & 1;
    const showMarker = showMask & 2;
    let mask = (showLine ? 1 : 0) | (!showMarker ? 2 : 0);
    if (!mask) {
      mask = 1;
    }
    return { showMask: mask };
  },
  saveTyphoonDetail(state, { payload: { id, data } }) {
    return {
      data: {
        ...state.data,
        [id]: data,
      },
    };
  },
  loadTyphoonDatas(_state) {
    return {
      loading: true,
    };
  },
  saveTyphoonDatas(state, { payload = {} }) {
    return {
      loading: false,
      data: {
        ...state.data,
        ...payload,
      },
    };
  },
  saveTyphoonList(_state, { payload = [] }) {
    return {
      loading: false,
      current: payload.filter((item) => item.id6).map((item) => item.id6),
    };
  },
  saveHydroStationList(_state, { payload = [] }) {
    return {
      hydroStations: payload,
    };
  },
  saveKeyword(_state, { payload = '' }) {
    return {
      currentKeyword: payload,
      searchResult: undefined,
    };
  },
  saveSearchResult(_state, { payload = [] }) {
    return {
      searchResult: payload,
    };
  },
  clearSearch() {
    return {
      currentKeyword: '',
      searchResult: undefined,
      currentPOI: undefined,
    };
  },
  saveCurrentPOI(_state, { payload }) {
    return {
      currentPOI: payload,
    };
  },
};
const reducer = (state, action) => {
  const actionReducer = reducers[action.type];
  if (actionReducer) {
    const nextState = actionReducer(state, action);
    return { ...state, ...nextState };
  }
  return state;
};
const SuperTY = `194902
194914
195001
195008
195009
195014
195015
195103
195106
195107
195111
195113
195116
195213
195216
195219
195220
195221
195222
195227
195302
195303
195306
195309
195311
195314
195315
195317
195404
195405
195406
195407
195408
195411
195412
195413
195414
195415
195505
195513
195514
195518
195519
195601
195602
195604
195607
195609
195611
195614
195701
195702
195703
195704
195706
195708
195709
195710
195712
195713
195715
195716
195717
195718
195801
195802
195806
195807
195809
195810
195811
195813
195814
195816
195818
195819
195820
195822
195903
195908
195912
195913
195916
195917
195919
195922
195923
195924
195925
195926
195927
196007
196008
196010
196011
196019
196026
196027
196028
196029
196103
196115
196118
196119
196122
196123
196128
196129
196202
196210
196212
196214
196217
196221
196223
196227
196301
196304
196307
196309
196310
196313
196314
196317
196318
196319
196320
196325
196405
196406
196411
196412
196414
196420
196423
196435
196437
196510
196512
196515
196518
196520
196521
196524
196525
196529
196530
196533
196604
196613
196616
196617
196619
196624
196704
196718
196720
196722
196725
196729
196731
196733
196734
196801
196803
196804
196812
196813
196815
196816
196817
196818
196822
196824
196827
196905
196911
196914
196915
196916
196917
197001
197003
197008
197009
197010
197014
197015
197017
197018
197023
197105
197112
197115
197116
197118
197121
197125
197127
197130
197133
197206
197208
197209
197213
197220
197224
197225
197227
197229
197303
197306
197314
197315
197317
197411
197421
197427
197503
197505
197513
197515
197520
197606
197609
197613
197617
197622
197705
197709
197717
197718
197814
197825
197827
197907
197910
197919
197920
198009
198015
198019
198021
198118
198120
198124
198126
198210
198211
198212
198214
198220
198222
198223
198304
198305
198309
198310
198317
198319
198406
198407
198421
198422
198425
198521
198604
198607
198612
198615
198624
198705
198707
198709
198711
198713
198714
198719
198805
198820
198902
198908
198918
198926
198929
198930
199020
199027
199029
199030
199031
199104
199107
199118
199123
199128
199212
199216
199223
199229
199231
199302
199314
199320
199405
199407
199416
199424
199426
199429
199434
199512
199521
199608
199624
199710
199714
199720
199723
199724
199725
199726
199729
199810
200010
200014
200018
200122
200125
200209
200302
200314
200321
200406
200416
200505
200513
200514
200519
200603
200608
200612
200613
200614
200619
200620
200621
200704
200708
200712
200715
200802
200813
200815
200910
200914
200917
200918
200920
200922
201011
201013
201102
201106
201109
201111
201115
201204
201215
201216
201217
201224
201307
201311
201319
201327
201328
201330
201408
201409
201411
201413
201419
201420
201422
201504
201506
201507
201509
201511
201513
201515
201516
201517
201521
201524
201525
201526
201527
201601
201610
201614
201617
201618
201621
201622
201626
201718
201721
201803
201808
201821
201822
201824
201825
201826
201902
201909
201913
201919
201921
201923
201928
202009
202010
202019
202102
202114
202116
202121
202122
202211
202214
202216`.split('\n')
const STY = `194901
194904
194905
194908
194909
194910
194916
194918
195002
195013
195101
195109
195112
195202
195203
195209
195218
195223
195301
195304
195307
195308
195310
195316
195401
195410
195503
195508
195509
195510
195511
195515
195516
195606
195608
195610
195612
195615
195705
195707
195711
195804
195812
195817
195906
195907
195910
195920
196006
196012
196017
196024
196105
196108
196111
196112
196113
196203
196216
196220
196222
196228
196311
196401
196403
196419
196421
196427
196507
196514
196523
196531
196602
196603
196608
196612
196625
196627
196630
196706
196708
196726
196730
196732
196802
196819
196821
196823
196826
196901
196903
196909
196913
197011
197016
197103
197111
197119
197122
197123
197124
197201
197202
197207
197216
197219
197223
197226
197228
197404
197416
197418
197424
197425
197429
197508
197601
197603
197607
197608
197704
197715
197719
197802
197817
197823
197824
197901
197903
197905
197912
197915
197917
197922
198002
198003
198008
198011
198012
198018
198023
198101
198106
198116
198122
198123
198127
198202
198204
198216
198218
198224
198225
198405
198411
198424
198426
198427
198503
198510
198512
198520
198526
198608
198613
198614
198616
198619
198623
198626
198627
198701
198712
198716
198721
198801
198809
198813
198814
198823
198825
198911
198922
198927
199006
199008
199016
199025
199106
199112
199115
199120
199121
199122
199124
199203
199211
199218
199219
199222
199228
199232
199308
199311
199316
199319
199324
199413
199417
199420
199431
199432
199435
199509
199514
199518
199520
199602
199605
199615
199616
199702
199707
199712
199717
199718
199721
199804
199811
199917
200001
200003
200008
200109
200112
200115
200120
200123
200202
200203
200204
200205
200206
200207
200213
200215
200216
200217
200221
200226
200306
200308
200310
200313
200317
200318
200401
200402
200407
200410
200413
200418
200421
200422
200423
200424
200427
200503
200504
200509
200511
200515
200517
200518
200520
200601
200615
200622
200702
200705
200711
200719
200805
200806
200808
200814
200901
201007
201012
201014
201117
201119
201203
201208
201211
201214
201221
201223
201321
201323
201324
201325
201326
201329
201410
201415
201418
201512
201520
201522
201604
201612
201616
201620
201623
201705
201712
201713
201719
201720
201813
201819
201820
201828
201908
201910
201915
201920
201925
201929
202001
202008
202018
202022
202106
202201
202210
202212
202218
202220`.split('\n')

const TY = `194906
194911
194912
194913
194917
195003
195004
195006
195007
195011
195012
195016
195019
195105
195108
195110
195114
195115
195117
195201
195206
195210
195211
195212
195214
195215
195225
195226
195305
195312
195313
195402
195403
195409
195501
195502
195504
195506
195507
195512
195517
195603
195605
195613
195616
195617
195618
195714
195803
195805
195815
196003
196004
196005
196013
196015
196016
196018
196023
196025
196104
196106
196114
196117
196121
196126
196127
196204
196205
196206
196207
196208
196211
196213
196215
196219
196225
196226
196302
196305
196308
196312
196322
196323
196324
196402
196407
196408
196409
196416
196422
196426
196431
196434
196501
196508
196509
196516
196601
196606
196607
196610
196611
196614
196620
196621
196622
196623
196626
196702
196707
196709
196710
196711
196712
196713
196716
196719
196727
196728
196805
196807
196808
196809
196814
196825
196904
196908
196910
196912
197104
197108
197110
197113
197117
197126
197129
197131
197132
197205
197210
197212
197217
197221
197222
197301
197302
197304
197308
197309
197313
197314
197316
197318
197403
197405
197408
197414
197419
197422
197423
197501
197504
197506
197510
197511
197512
197514
197516
197519
197605
197610
197611
197614
197619
197620
197621
197623
197703
197710
197713
197716
197806
197807
197808
197809
197811
197813
197820
197821
197902
197909
198010
198013
198016
198105
198115
198117
198119
198203
198205
198213
198215
198219
198303
198313
198320
198403
198410
198413
198420
198504
198505
198506
198507
198509
198511
198514
198515
198518
198519
198523
198601
198605
198611
198618
198703
198704
198708
198710
198715
198718
198723
198802
198803
198807
198810
198817
198821
198822
198824
198903
198905
198909
198913
198914
198915
198921
198923
198924
198925
198928
199001
199003
199005
199009
199010
199012
199014
199015
199018
199019
199021
199022
199102
199105
199108
199109
199111
199117
199204
199206
199208
199217
199220
199225
199226
199227
199310
199312
199317
199318
199321
199326
199327
199328
199329
199402
199414
199418
199419
199422
199427
199430
199503
199510
199513
199515
199604
199607
199610
199612
199613
199617
199618
199619
199620
199622
199623
199706
199708
199709
199806
199807
199809
199903
199904
199906
199909
199919
200004
200009
200012
200016
200017
200019
200020
200021
200023
200102
200103
200104
200106
200107
200108
200111
200116
200117
200118
200119
200121
200210
200223
200224
200225
200303
200311
200315
200316
200320
200404
200408
200412
200415
200417
200425
200521
200523
200605
200606
200607
200618
200701
200709
200714
200721
200723
200724
200801
200807
200812
200822
200902
200906
200908
200915
200916
200921
201002
201003
201010
201116
201202
201209
201210
201213
201312
201313
201320
201403
201502
201503
201510
201523
201709
201714
201715
201722
201727
201807
201812
201917
201918
201924
201926
202004
202006
202007
202014
202017
202020
202105
202107
202118
202120
202203
202209
202213
202217
202222
202303
202304
202308`.split('\n')

const STS = `194903
194907
194915
195005
195010
195017
195018
195102
195104
195204
195205
195207
195208
195217
195224
195808
195821
195901
195902
195905
195921
196014
196109
196116
196120
196124
196201
196218
196229
196230
196303
196306
196315
196404
196410
196418
196425
196428
196429
196430
196432
196433
196436
196502
196506
196511
196513
196517
196519
196526
196527
196528
196532
196605
196615
196618
196628
196629
196703
196714
196715
196717
196721
196723
196724
196735
196806
196810
196811
196820
196906
196907
196918
196919
197002
197004
197005
197012
197019
197020
197021
197022
197101
197102
197106
197107
197114
197120
197128
197211
197214
197215
197218
197230
197305
197310
197311
197312
197319
197320
197321
197401
197402
197406
197407
197409
197410
197412
197413
197415
197417
197420
197426
197507
197509
197517
197604
197612
197615
197616
197618
197624
197701
197702
197707
197708
197711
197712
197714
197801
197803
197804
197810
197812
197816
197818
197819
197822
197826
197828
197906
197908
197911
197913
197921
197923
198001
198004
198005
198006
198007
198014
198017
198022
198024
198102
198104
198107
198108
198109
198110
198111
198112
198113
198114
198201
198207
198208
198209
198217
198226
198302
198307
198311
198312
198314
198316
198321
198322
198323
198402
198404
198408
198409
198412
198415
198416
198417
198423
198501
198508
198513
198516
198517
198522
198602
198603
198606
198609
198610
198617
198621
198622
198706
198720
198804
198806
198811
198815
198818
198819
198826
198904
198907
198910
198912
198917
198919
198920
199004
199007
199011
199017
199028
199101
199103
199113
199116
199119
199126
199127
199129
199201
199207
199210
199214
199221
199301
199303
199305
199306
199307
199309
199315
199323
199325
199401
199403
199409
199412
199421
199423
199436
199504
199505
199507
199508
199516
199519
199523
199603
199606
199609
199611
199626
199713
199715
199716
199722
199727
199802
199803
199805
199815
199902
199905
199907
199910
199912
199914
199916
199918
199922
200006
200013
200101
200212
200214
200218
200222
200304
200305
200307
200309
200319
200403
200405
200419
200501
200502
200507
200508
200510
200512
200604
200609
200610
200706
200710
200717
200720
200804
200809
200819
200903
200907
200911
200912
200919
201004
201005
201006
201009
201105
201108
201110
201112
201121
201201
201205
201207
201218
201219
201220
201222
201303
201306
201309
201310
201315
201317
201318
201405
201412
201414
201416
201417
201421
201508
201603
201605
201606
201608
201609
201619
201625
201702
201703
201704
201706
201716
201724
201805
201810
201811
201814
201815
201816
201818
201823
201829
201901
201911
201912
201922
201927
202011
202012
202013
202016
202021
202113
202310
202312`.split('\n')
const TS = `195911
195915
196009
196021
196022
196101
196107
196110
196125
196209
196224
196316
196413
196415
196424
196503
196505
196522
196609
196701
196705
196902
197006
197007
197013
197024
197109
197134
197203
197204
197307
197428
197430
197431
197502
197518
197602
197625
197706
197805
197815
197904
197914
197916
197918
198020
198103
198121
198125
198206
198221
198301
198306
198308
198315
198318
198401
198418
198419
198502
198524
198525
198620
198625
198702
198717
198722
198808
198812
198816
198901
198906
198916
199002
199013
199023
199026
199114
199125
199202
199209
199213
199215
199224
199230
199313
199322
199404
199406
199408
199410
199411
199415
199425
199428
199433
199501
199502
199506
199511
199517
199522
199601
199614
199625
199705
199711
199719
199728
199801
199812
199813
199814
199911
199913
199915
199920
200002
200005
200007
200011
200015
200105
200110
200113
200114
200124
200201
200208
200211
200219
200220
200301
200312
200409
200411
200414
200420
200426
200428
200429
200506
200516
200522
200602
200611
200616
200617
200623
200703
200707
200713
200716
200718
200722
200803
200810
200811
200816
200817
200818
200820
200821
200904
200905
200909
200913
201001
201008
201101
201103
201104
201107
201113
201114
201118
201120
201206
201212
201225
201301
201302
201304
201305
201308
201314
201316
201322
201331
201401
201402
201404
201406
201407
201423
201505
201514
201518
201519
201602
201607
201611
201613
201615
201624
201701
201707
201708
201710
201711
201717
201725
201726
201801
201802
201804
201806
201809
201817
201827
201903
201904
201905
201906
201907
201914
201916
202002
202003
202005
202015
202023
202101
202103
202104
202108
202109
202110
202111
202112
202115
202117
202119
202202
202204
202205
202206
202207
202208
202215
202219
202221
202223
202224
202301
202313`.split('\n')
const initState = {
  startYear: new Date().getFullYear() - 5,
  endYear: new Date().getFullYear() - 1,
  minLevel: 'SuperTY',
  showMask: 3,
  selected: undefined,
  loading: false,
  data: {},
  current: [],
  hydroStations: [],
  TY_IDS: {
    TD: [...TS, ...STS, ...TY, ...STY, ...SuperTY].sort(),
    TS: [...TS, ...STS, ...TY, ...STY, ...SuperTY].sort(),
    STS: [...STS, ...TY, ...STY, ...SuperTY].sort(),
    TY: [...TY, ...STY, ...SuperTY].sort(),
    STY: [...STY, ...SuperTY].sort(),
    SuperTY: SuperTY,
  },
  currentKeyword: '',
  searchResult: undefined,
};

export default function useModel() {
  return useReducer(reducer, initState);
}
