export function loadTyphooneDetail(id) {
  const v = localStorage.getItem(`ty_${id}`);
  if (v) {
    try {
      const data = JSON.parse(v);
      if (!data.active) {
        return Promise.resolve(JSON.parse(v));
      }
    } catch (e) {
      console.log(e);
    }
  }
  return fetch(`/data/NMC/${id}.json`)
    .then((res) => res.json())
    .then((data) => {
      localStorage.setItem(`ty_${id}`, JSON.stringify(data));
      return data;
    });
}

export function batchLoadTyphooneDetail(ids = []) {
  return Promise.all(ids.map((id) => loadTyphooneDetail(id))).then((datas) =>
    datas.reduce((pre, cur, i) => ({ ...pre, [ids[i]]: cur }), {})
  );
}

export function loadTyphoonList(year = 'current') {
  return fetch(`/data/NMC/${year}.json`).then((res) => res.json());
}

export function loadHydroStationList() {
  return fetch(`/hydro.json`).then((res) => res.json());
}

export function tdtSearch(
  keyword,
  bbox = '70,4,136,55',
  level = 5,
  queryType = 1,
  page = 1,
  size = 10
) {
  const data = {
    keyWord: keyword,
    level: level,
    mapBound: bbox,
    queryType: queryType,
    start: (page - 1) * size,
    count: size,
  };
  return fetch(
    `https://api.tianditu.gov.cn/v2/search?postStr=${encodeURIComponent(
      JSON.stringify(data)
    )}&type=query&tk=4c4e154adeaf7aa9fcc140ba8cc2ee1e`
  ).then((res) => res.json());
}

export function amapSearch(keyword) {
  return fetch(
    `https://restapi.amap.com/v3/assistant/inputtips?s=rsv3&key=548d6b38ef15daf8e1bbcb97be8246e0&platform=JS&appname=yubb123&keywords=${encodeURIComponent(
      keyword
    )}&datatype=poi&jscode=99faf7c0b9a77c021d87b8370f820d66`
  ).then((res) => res.json());
}
