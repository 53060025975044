import React, { useCallback } from 'react';
import './SearchPanel.css';
import { amapSearch } from './service';
import classNames from 'classnames';
import { gcj02towgs84 } from 'coordtransform';

export default function ({
  dispatch,
  currentKeyword,
  searchResult,
  mapRef,
  currentPOI,
}) {
  const [keyword, setKeyword] = React.useState('');
  const [showSearch, setShowSearch] = React.useState(false);
  const searchHandler = useCallback(() => {
    if (!keyword) {
      setKeyword('');
      setShowSearch(false);
      dispatch({ type: 'clearSearch' });
      return;
    }
    if (keyword === currentKeyword) {
      setShowSearch(true);
      return;
    }
    dispatch({ type: 'saveKeyword', payload: keyword });
    // const map = mapRef.current.leafletElement;
    // const level = map.getZoom();
    // const bbox = map.getBounds().toBBoxString();
    // console.log(map, level, bbox);
    // tdtSearch(keyword, bbox, level).then((res) => {
    //   dispatch({ type: 'saveSearchResult', payload: res });
    // });
    amapSearch(keyword).then((res) => {
      dispatch({ type: 'saveSearchResult', payload: res });
      setShowSearch(true);
    });
  }, [dispatch, keyword, currentKeyword]);
  return (
    <div className="SearchPanel">
      <div className="SearchBar">
        <input
          value={keyword}
          placeholder="地名搜索"
          onChange={(e) => setKeyword(e.currentTarget.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') searchHandler();
          }}
        ></input>
        <button onClick={searchHandler}>搜索</button>
      </div>
      {/* {searchResult ? (
          searchResult.resultType === 1 ? (
            <div>{searchResult.pois.map(({nid, name, address})=>(
              <div key={nid}>{name}</div>
            ))}</div>
          ) : searchResult.resultType === 3 ? (
            <div>
              {[searchResult.area].map(
                ({ adminCode, name, lonlat, level, bound }) => (
                  <div key={adminCode}>{name}</div>
                )
              )}
            </div>
          ) : null
        ) : null} */}
      {showSearch && searchResult && searchResult.tips ? (
        <div className="SearchResult">
          {searchResult.tips.length > 0 ? (
            searchResult.tips
              .filter((item) => item.location && item.location.length > 0)
              .map(({ id, name, address, district, location }) => (
                <div
                  className={classNames({
                    SearchResultItem: true,
                    SearchResultItemSelected:
                      currentPOI && currentPOI.id === id,
                  })}
                  key={id}
                  onClick={() => {
                    let coords = location.split(',');
                    // 高德API返回的是GCJ02坐标系，要转成WGS84
                    coords = gcj02towgs84(
                      parseFloat(coords[0]),
                      parseFloat(coords[1])
                    );
                    dispatch({
                      type: 'saveCurrentPOI',
                      payload: {
                        id,
                        name,
                        address,
                        district,
                        location: [coords[1], coords[0]],
                      },
                    });
                    mapRef.current.leafletElement.closePopup();
                    mapRef.current.leafletElement.panTo([coords[1], coords[0]]);
                  }}
                >
                  <strong>{name}</strong>
                  <br />
                  {district}
                  {address}
                </div>
              ))
          ) : (
            <div className="SearchResultItem">未搜索到相关结果</div>
          )}
        </div>
      ) : null}
      {showSearch ? (
        <div className="SearchAction">
          <div
            onClick={() => {
              setKeyword('');
              setShowSearch(false);
              dispatch({ type: 'clearSearch' });
            }}
          >
            清空搜索结果
          </div>
          <div
            onClick={() => {
              setShowSearch(false);
            }}
          >
            隐藏搜索结果
          </div>
        </div>
      ) : null}
    </div>
  );
}
