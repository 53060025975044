import React from 'react';
import { CircleMarker, LayerGroup, Popup } from 'react-leaflet';

export default function (props) {
  const { hydroStations } = props;
  return (
    <LayerGroup>
      {hydroStations.map(
        ({ code, lat, lon, name, wrz, bsnm, rvnm, z_max, z_max_q, z_t }) => (
          <CircleMarker
            key={code}
            center={[lat, lon]}
            stroke={false}
            radius={6}
            color="blue"
            fillOpacity={0.8}
            // pane={selected ? "typhoon-marker-selected" : "typhoon-marker"}
            // onClick={clickHandler}
          >
            <Popup>
              <strong>
                {code} {name}
              </strong>
              <br />
              流域：{bsnm}
              <br />
              河流：{rvnm}
              <br />
              警戒水位：{wrz || '--'}米
              <br />
              历史最高水位：{z_max ? `${z_max}米` : '--'}
              <br />
              最高水位发生时间：{z_t ? `${z_t.slice(0, 10)}` : '--'}
              <br />
              最高水位时流量：{z_max_q ? `${z_max_q}米³/秒` : '--'}
            </Popup>
          </CircleMarker>
        )
      )}
    </LayerGroup>
  );
}
