import React, { useState } from 'react';
import App from './App';
import Login from './Login';

function AppLogin() {
  const [user, setUser] = useState(() => localStorage.getItem('user') || '');
  return user ? (
    <App
      onLogout={() => {
        setUser('');
        localStorage.removeItem('user');
      }}
    />
  ) : (
    <Login
      onLoginSuccess={user => {
        setUser('user');
        localStorage.setItem('user', user);
      }}
    />
  );
}
export default AppLogin;
