import React, { useCallback, memo } from "react";
import TyphoonLevelStyle from "./TyphoonLevelStyle";
import { useState } from "react";
import "leaflet/dist/leaflet.css";
import "./TyphoonLegend.css";

const LegendItems = ["TD", "TS", "STS", "TY", "STY", "SuperTY"].map(level => {
  const { name, color } = TyphoonLevelStyle[level];
  return (
    <div key={level} className="item">
      <span style={{ backgroundColor: color }}>&nbsp;</span> {name}
    </div>
  );
});

const TyphoonLegend = () => {
  const [expanded, setExpanded] = useState(false);
  const clickHandler = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded, setExpanded]);
  return expanded ? (
    <div className="TyphoonLegend">
      <div>台风路径点</div>
      {LegendItems}
      <hr />
      <div className="item">
        <span style={{ backgroundColor: 'blue' }}>&nbsp;</span> 水文站点
      </div>
      <button onClick={clickHandler}>隐藏图例</button>
    </div>
  ) : (
    // 使用与leaflet 缩放控件按钮一致的样式，必须用a
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a onClick={clickHandler} className="TyphoonLegendFolded">
      图例
    </a>
  );
};

export default memo(TyphoonLegend);
