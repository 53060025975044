import React, { useCallback, useState } from 'react';
import './Login.css';

function Login({ onLoginSuccess = () => {} }) {
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const onClick = useCallback(() => {
    console.log('登录', username, password);
    if(!username || !password) {
        alert('请输入用户名和密码');
        return;
    }
    if(username === 'demo' && password === 'whxsy123456'){
        onLoginSuccess(username);
    } else {
        alert('用户名或密码错误');
    }
  }, [username, password, onLoginSuccess]);
  return (
    <div className="login_container">
      <div className="login_panel">
        <div className="login_title">新视野风险地图</div>
        <div className="login_form">
          <input
            placeholder="用户名"
            value={username}
            onChange={(e) => setUserName(e.target.value)}
          ></input>
        </div>
        <div className="login_form">
          <input
            placeholder="密码"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
          ></input>
        </div>
        <div className="login_btn">
          <button type="button" onClick={onClick}>
            登录
          </button>
        </div>
      </div>
      <div className="copyright">
        ©{new Date().getFullYear()} 武汉新视野风险管理科技有限公司
      </div>
    </div>
  );
}

export default Login;
