import React from "react";
import ReactDOM from "react-dom";
import { MapControl, withLeaflet } from "react-leaflet";
import { Control, DomUtil, DomEvent } from "leaflet";
import classnames from "classnames";
import "./CoordinatesControl.css";

const DumbControl = Control.extend({
  options: {
    className: "",
    onOff: "",
    handleOff: function noop() {}
  },

  onAdd(/* map */) {
    var _controlDiv = DomUtil.create("div", this.options.className);
    DomEvent.disableClickPropagation(_controlDiv);
    return _controlDiv;
  },

  onRemove(map) {
    if (this.options.onOff) {
      map.off(this.options.onOff, this.options.handleOff, this);
    }

    return this;
  }
});

const latLngToDecimalString = (lat, lng) =>
  `${Math.abs(lat).toFixed(6)}°${lat < 0 ? "S" : "N"} ${Math.abs(lng).toFixed(
    6
  )}°${lng < 0 ? "W" : "E"}`;

const padZero = (a, n) => ("0000" + a).slice(-n);

const decimalToDms = n => {
  if (n < 0) {
    return `-${decimalToDms(Math.abs(n))}`;
  }
  const d = Math.floor(n);
  const m = Math.floor((n - d) * 60);
  const s = ((n - d) * 60 - m) * 60;
  return `${d}°${padZero(m, 2)}′${padZero(s.toFixed(2), 5)}″`;
};

const latLngToDmsString = (lat, lng) =>
  `${decimalToDms(Math.abs(lat))}${lat < 0 ? "S" : "N"} ${decimalToDms(
    Math.abs(lng)
  )}${lng < 0 ? "W" : "E"}`;

class CoordinatesControl extends MapControl {
  state = {
    isDms: false,
    isCursor: false,
    center: [0, 0],
    cursor: [0, 0]
  };

  createLeafletElement(props) {
    return new DumbControl({ ...props });
  }

  mouseoverHandler = () => {
    const { leaflet } = this.props;
    const { map } = leaflet;
    map.on("mousemove", this.mousemoveHandler);
    map.on("mouseout", this.mouseoutHandler);
    this.setState({ isCursor: true });
  };
  mousemoveHandler = e => {
    const { lat, lng } = e.latlng.wrap();
    this.setState({ cursor: [lat, lng] });
  };
  mouseoutHandler = () => {
    const { leaflet } = this.props;
    const { map } = leaflet;
    map.off("mousemove", this.mousemoveHandler);
    map.off("mouseout", this.mouseoutHandler);
    this.setState({ isCursor: false });
  };

  clickHandler = () => {
    this.setState(state => ({
      isDms: !state.isDms
    }));
  };

  centerChangeHandler = () => {
    const { leaflet } = this.props;
    const { map } = leaflet;
    const { lat, lng } = map.getCenter().wrap();
    this.setState({ center: [lat, lng] });
  };

  componentDidMount() {
    super.componentDidMount();
    const { leaflet } = this.props;
    const { map } = leaflet;

    map.on("mouseover", this.mouseoverHandler);
    map.on("moveend", this.centerChangeHandler);
    map.on("zoomend", this.centerChangeHandler);
    const { lat, lng } = map.getCenter().wrap();
    this.setState({ center: [lat, lng] });

    // This is needed because the control is only attached to the map in
    // MapControl's componentDidMount, so the container is not available
    // until this is called. We need to now force a render so that the
    // portal and children are actually rendered.
    this.forceUpdate();
  }

  componentWillUnmount() {
    const { leaflet } = this.props;
    const { map } = leaflet;
    map.off("mouseover", this.mouseoverHandler);
    map.off("mouseout", this.mouseoutHandler);
    map.off("mousemove", this.mousemoveHandler);
    map.off("moveend", this.centerChangeHandler);
    map.off("zoomend", this.centerChangeHandler);
    super.componentWillUnmount();
  }

  render() {
    const { isDms, isCursor, center, cursor } = this.state;
    if (!this.leafletElement || !this.leafletElement.getContainer()) {
      return null;
    }
    const [lat, lng] = isCursor ? cursor : center;
    return ReactDOM.createPortal(
      <div
        className={classnames("CoordinatesControl", { cursor: isCursor })}
        title="点击可在十进制格式与度分秒格式之间切换"
        onClick={this.clickHandler}
      >
        {isDms ? latLngToDmsString(lat, lng) : latLngToDecimalString(lat, lng)}
      </div>,
      this.leafletElement.getContainer()
    );
  }
}
export default withLeaflet(CoordinatesControl);
